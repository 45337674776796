.notificationBox {
  margin-bottom: 100px;
  background: transparent;
  padding: 0;
  border: none;
}
.noData {
  text-align: center;
}

.notificationContainer{
  min-height: 744px;
}

.notReadSep {
  background: -moz-linear-gradient(
    left,
    rgba(160, 251, 172, 0) 0%,
    rgba(144, 228, 177, 0.63) 50%,
    rgba(144, 228, 177, 0.63) 51%,
    rgba(51, 210, 104, 0) 99%,
    rgba(180, 240, 191, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(160, 251, 172, 0) 0%,
    rgba(144, 228, 177, 0.63) 50%,
    rgba(144, 228, 177, 0.63) 51%,
    rgba(51, 210, 104, 0) 99%,
    rgba(180, 240, 191, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(160, 251, 172, 0) 0%,
    rgba(144, 228, 177, 0.63) 50%,
    rgba(144, 228, 177, 0.63) 51%,
    rgba(51, 210, 104, 0) 99%,
    rgba(180, 240, 191, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}


.pagination {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 20px;
}

.next svg {
  transform: rotate(180deg);
}

.prev,
.next {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #242222;
  margin-left: 1px;
  border: 1px solid var(--list-line);
}

.prev:hover,
.next:hover {
  background-color: #57575777;
  cursor: pointer;
}

.prev:hover svg,
.next:hover svg {
  color: var(--text-selected);
}

.item {
  display: flex;
  column-gap: 25px;
  overflow: visible;
  margin-bottom: 30px;
  /* transition: all 2s ease; */
}

.item:last-child {
  margin-bottom: 0;
}

.item:last-child .type::before {
  display: none;
}

.item:last-child .text {
  padding-bottom: 0;
}

.type {
  overflow: visible;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}

.type::before {
  content: "";
  display: block;
  height: 100%;
  width: 0px;
  border-left: dashed 2px rgba(109, 109, 109, .5);
  padding: 20px;
  display: inline-block;
  font-size: 40px;
  position: absolute;
  /* bottom: 0; */
  left: calc(50% - 0.5px);
  z-index: -1;
}


.text {
  color: var(--light-text-secondary);
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.time {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  color: var(--text-subtitle);
  margin-left: auto;
  padding-bottom: 20px;
  height: auto;
  text-transform: uppercase;
}

.shortDesc {
  padding-bottom: 30px;
  padding-left: 20px;
  font-size: var(--font-size-base);
  color: rgba(255, 255, 255, 0.7);
}
.readMoreBtnWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.button {
  background: rgb(174, 174, 174, 0.1);
  gap: 10px;
}

@media screen and (max-width: 575px) {
  .type {
    display: none;
  }
}
