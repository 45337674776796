
.separatorHorizontal{
    margin:0 auto;
    display:block;
    width:100%;
    height:2px;
    background: -moz-linear-gradient(left, rgba(30,87,153,0) 0%, rgba(131, 129, 129, 0.63) 50%, rgba(131, 129, 129, 0.63) 51%, rgba(225,228,226,0) 99%, rgba(229,229,229,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(30,87,153,0) 0%,rgba(131, 129, 129, 0.63) 50%,rgba(131, 129, 129, 0.63) 51%,rgba(225,228,226,0) 99%,rgba(229,229,229,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(30,87,153,0) 0%,rgba(131, 129, 129, 0.63) 50%,rgba(131, 129, 129, 0.63) 51%,rgba(225,228,226,0) 99%,rgba(229,229,229,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.separatorVertical {
    margin:0 auto;
    display:block;
    height:100%;
    width:2px;
    background: -moz-linear-gradient(bottom, rgba(30,87,153,0) 0%, rgba(131, 129, 129, 0.63) 50%, rgba(131, 129, 129, 0.63) 51%, rgba(225,228,226,0) 99%, rgba(229,229,229,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(bottom, rgba(30,87,153,0) 0%,rgba(131, 129, 129, 0.63) 50%,rgba(131, 129, 129, 0.63) 51%,rgba(225,228,226,0) 99%,rgba(229,229,229,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(30,87,153,0) 0%,rgba(131, 129, 129, 0.63) 50%,rgba(131, 129, 129, 0.63) 51%,rgba(225,228,226,0) 99%,rgba(229,229,229,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}