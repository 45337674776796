/* Notification styling */
.notification {
	border: 1px solid var(--card-border);
	padding: 10px;
	margin: 10px 0; /* Adjusted margin to create space between notifications */
	animation: slideIn 0.3s ease-in-out;
	border-radius: 8px;
	background: var(--bg-like-blur);
	color: var(--light);
	width: 600px;
  
	display: flex;
	justify-content: space-between;
	align-items: center;
  }
  
  /* Keyframe animation for slide in effect */
  @keyframes slideIn {
	from {
	  transform: translateX(+100%);
	}
	to {
	  transform: translateX(0);
	}
  }
  