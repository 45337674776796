.tabsSection {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}
.tabsSection {
  margin-left: 30px;
}
.tapItem {
  cursor: pointer;
}
.tapItemActive {
  color: var(--text-selected);
  font-weight: 600;
}
